
import Mindmap from "@/components/shared/mindmap/index.vue";
import { defineComponent, computed, onMounted, watch } from "@vue/runtime-core";
import { ComponentPublicInstance, ref } from "vue";
import MindmapDemoLayout from "@/components/layouts/mindmapDemoLayout/MindmapDemoLayout.vue";
import { setModal } from "@/core/helpers/config";
import { useStore } from "vuex";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import Logo from "@/components/layouts/mainLayout/header/Logo.vue";
import useTranslate from "@/core/composable/useTranslate";
import { useI18n } from "vue-i18n";

import {
  VOnboardingWrapper,
  useVOnboarding,
  VOnboardingStep,
} from "v-onboarding";
import "v-onboarding/dist/style.css";
import { useRoute, useRouter } from "vue-router";
export default defineComponent({
  name: "my-tour",
  components: {
    Mindmap,
    MindmapDemoLayout,
    Logo,
    VOnboardingWrapper,
    VOnboardingStep,
  },
  setup: () => {
    const store = useStore();
    const i18n = useI18n();
    const { translate } = useTranslate();
    const route = useRoute();
    const router = useRouter();
    const mindmapData = computed(() => store.getters.getNestedNodes);

    const wrapper = ref<ComponentPublicInstance<
      typeof VOnboardingWrapper
    > | null>(null);
    const { start } = useVOnboarding(wrapper);

    onMounted(() => {
      store.dispatch(Actions.LOAD_NODES, route.params.sampleId).then((res) => {
        if (res.error) router.push({ name: "404" });
      });
      store.dispatch(Actions.FETCH_NODE_TYPES);

      if (!store.getters.getMindmapUserId)
        setTimeout(() => {
          setModal("UserDetails", {}, { closeable: false });
        }, 1000);
    });
    const handleEditNode = (payload) => {
      setModal("SimpleTicketModal", payload);
    };

    const handleAddNode = (payload) => {
      setModal("SimpleTicketModal", {
        ...payload,
        type: store.getters.getNodeTypeByName(payload.type).id,
        userJourney: [""],
        acceptanceTest: [""],
      });
    };
    const handleDeleteNode = (payload) => {
      console.log("delete", payload);
      store.commit(Mutations.DELETE_MINDMAP_NODE, { id: payload.id });
    };
    const calculateEstimation = () =>
      store.dispatch(Actions.FETCH_CALCULATE_ESTIMATION);

    const addModule = (name) => store.dispatch(Actions.ADD_MODULE, name);

    const exitTour = (exit) => {
      exit();
    };
    const steps = ref([
      {
        attachTo: {
          element: "#launch-tour",
        },
        content: {
          title: `${translate("mindmapToor.good")}`,
          description: `${translate(
            "mindmapToor.description"
          )} 🔥 DevFactory 🔥`,
        },
        on: {
          beforeStep: () => {
            setModal("");
          },
          afterStep: () => {
            setModal("");
          },
        },
      },
      {
        attachTo: {
          element: "#functionality-button",
        },
        content: {
          title: `${translate("mindmapToor.tryCreateFeature")}`!,
          description: `${translate("mindmapToor.clickNextButton")}`,
        },
        on: {
          beforeStep: () => {
            setModal("");
          },
        },
      },
      {
        attachTo: {
          element: "#input-functionality-1",
        },
        content: {
          title: `${translate("mindmapToor.chooseType")}`,
          description: `Page , Section , Action , ${translate(
            "mindmapToor.other"
          )}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: " ",
                type: 1,
                userJourney: [""],
                acceptanceTest: [""],
                description: "",
              },
              { closeable: false }
            );
          },
        },
      },
      {
        attachTo: {
          element: "#input-functionality-2",
        },
        content: {
          title: `${translate("mindmapToor.titleFunctionality")}`,
          description: `${translate("mindmapToor.giveTitle")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: `${translate("mindmapToor.devfactoryDemoTitle")}`,
                type: 1,
                userJourney: [""],
                acceptanceTest: [""],
                description: "",
              },
              { closeable: false }
            );
          },
        },
      },
      {
        attachTo: {
          element: "#input-functionality-3",
        },
        content: {
          title: `${translate("description")}`,
          description: `${translate("mindmapToor.describeFunctionality")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: `${translate("mindmapToor.devfactoryDemoTitle")}`,
                type: 1,
                userJourney: [""],
                acceptanceTest: [""],
                description: `${translate(
                  "mindmapToor.devfactoryDemoDescription"
                )}`,
              },
              { closeable: false }
            );
          },
        },
      },
      {
        attachTo: {
          element: "#input-functionality-4",
        },
        content: {
          title: `${translate("mindmapToor.userJourney")}`,
          description: `${translate("mindmapToor.addJourney")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: `${translate("mindmapToor.devfactoryDemoTitle")}`,
                type: 1,
                userJourney: [
                  `${translate("mindmapToor.devfactoryDemoUserJourney")}`,
                ],
                acceptanceTest: [""],
                description: `${translate(
                  "mindmapToor.devfactoryDemoDescription"
                )}`,
              },
              { closeable: false }
            );
          },
        },
      },

      {
        attachTo: {
          element: "#input-functionality-5",
        },
        content: {
          title: `${translate("acceptanceTest")}`,
          description: `${translate("mindmapToor.addAdmissionTests")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: `${translate("mindmapToor.devfactoryDemoTitle")}`,
                type: 1,
                userJourney: [
                  `${translate("mindmapToor.devfactoryDemoUserJourney")}`,
                ],
                acceptanceTest: [
                  `${translate("mindmapToor.devfactoryDemoAdmission")}`,
                ],
                description: `${translate(
                  "mindmapToor.devfactoryDemoDescription"
                )}`,
              },
              { closeable: false }
            );
          },
        },
      },
      {
        attachTo: {
          element: "#input-functionality-6",
        },
        content: {
          title: `${translate("mindmapToor.clickSend")}`,
          description: `${translate("mindmapToor.clickToCreate")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "SimpleTicketModal",
              {
                text: `${translate("mindmapToor.devfactoryDemoTitle")}`,
                type: 1,
                userJourney: [
                  `${translate("mindmapToor.devfactoryDemoUserJourney")}`,
                ],
                acceptanceTest: [
                  `${translate("mindmapToor.devfactoryDemoAdmission")}`,
                ],
                description: `${translate(
                  "mindmapToor.devfactoryDemoDescription"
                )}`,
              },
              { closeable: false }
            );
          },
        },
      },
      {
        attachTo: {
          element: "#estimated-button",
        },
        content: {
          title: `${translate("mindmapToor.showEstimatedCost")}`,
          description: `${translate("mindmapToor.clickOnNext")}`,
        },
        on: {
          beforeStep: () => {
            setModal("");
          },
        },
      },
      {
        attachTo: {
          element: "#estimation",
        },
        content: {
          title: `${translate("mindmapToor.estimateMakeAppointment")}`,
        },
        on: {
          beforeStep: () => {
            setModal(
              "EstimatedModal",
              { periodInWeeks: 30, nbreOfMembers: 10, costInEuros: 5000 },
              { closeable: false }
            );
          },
        },
      },
    ]);
    const isClose = computed(() => {
      return store.getters.getModalState.options.isClose;
    });

    const setLang = () => {
      if (route.query?.lang == "en") {
        i18n.locale.value = "en";
      }
      if (route.query?.lang == "fr") {
        i18n.locale.value = "fr";
      }
    };

    watch(
      () => route.query.lang,
      async (newId) => {
        setLang();
      },
      { immediate: true }
    );

    watch(
      () => isClose.value,
      (val) => {
        if (val === true) start();
      }
    );

    return {
      setModal,
      mindmapData,
      handleEditNode,
      handleAddNode,
      handleDeleteNode,
      calculateEstimation,
      addModule,
      wrapper,
      steps,
      exitTour,
    };
  },
});
