import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createBlock as _createBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "flex flex-col p-5 Zindex" }
const _hoisted_2 = { class: "flex flex-col bg-light shadow-md p-4 text-center rounded border border-primary" }
const _hoisted_3 = { class: "font-medium text-xl" }
const _hoisted_4 = ["innerHTML"]
const _hoisted_5 = { class: "d-flex items-center space-x-2 mt-4 justify-content-evenly" }
const _hoisted_6 = ["onClick"]
const _hoisted_7 = ["onClick"]
const _hoisted_8 = ["onClick"]
const _hoisted_9 = {
  class: "bg-white shadow-bottom border-bottom d-flex justify-content-between px-6",
  style: { minHeight: '62px' }
}
const _hoisted_10 = {
  id: "launch-tour",
  class: "d-flex align-items-stretch flex-shrink-0 logo-nav"
}
const _hoisted_11 = { class: "d-flex py-4" }
const _hoisted_12 = ["disabled"]
const _hoisted_13 = { id: "mindmap" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_VOnboardingStep = _resolveComponent("VOnboardingStep")!
  const _component_VOnboardingWrapper = _resolveComponent("VOnboardingWrapper")!
  const _component_Logo = _resolveComponent("Logo")!
  const _component_Mindmap = _resolveComponent("Mindmap")!
  const _component_MindmapDemoLayout = _resolveComponent("MindmapDemoLayout")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_VOnboardingWrapper, {
      ref: "wrapper",
      steps: _ctx.steps
    }, {
      default: _withCtx(({ previous, next, step, exit, isFirst, isLast }) => [
        _createVNode(_component_VOnboardingStep, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_1, [
              _createElementVNode("div", _hoisted_2, [
                _createElementVNode("h3", _hoisted_3, _toDisplayString(step.content.title), 1),
                (step.content.description)
                  ? (_openBlock(), _createElementBlock("p", {
                      key: 0,
                      innerHTML: step.content.description,
                      class: "text-sm"
                    }, null, 8, _hoisted_4))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, [
                  (!isFirst)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 0,
                        onClick: previous,
                        class: "btn btn-sm btn-primary m-1 w-100px"
                      }, _toDisplayString(_ctx.$t("mindmapToor.previous")), 9, _hoisted_6))
                    : _createCommentVNode("", true),
                  (!isLast)
                    ? (_openBlock(), _createElementBlock("button", {
                        key: 1,
                        id: "next",
                        onClick: next,
                        class: "btn btn-sm btn-primary m-1 w-100px"
                      }, _toDisplayString(_ctx.$t("mindmapToor.next")), 9, _hoisted_7))
                    : _createCommentVNode("", true),
                  _createElementVNode("button", {
                    onClick: ($event: any) => (_ctx.exitTour(exit)),
                    class: "btn btn-sm btn-primary m-1 w-100px"
                  }, _toDisplayString(isLast
                    ? `${_ctx.$t("mindmapToor.finish")}`
                    : `${_ctx.$t("mindmapToor.skipToor")}`), 9, _hoisted_8)
                ])
              ])
            ])
          ]),
          _: 2
        }, 1024)
      ]),
      _: 1
    }, 8, ["steps"]),
    _createVNode(_component_MindmapDemoLayout, null, {
      default: _withCtx(() => [
        _createElementVNode("nav", _hoisted_9, [
          _createElementVNode("div", _hoisted_10, [
            _createVNode(_component_Logo)
          ]),
          _createElementVNode("div", _hoisted_11, [
            _createElementVNode("button", {
              id: "functionality-button",
              class: "btn btn-sm btn-primary me-4",
              onClick: _cache[0] || (_cache[0] = () => _ctx.setModal('SimpleTicketModal', { autoclose: false }))
            }, _toDisplayString(_ctx.$t("createFunctionality")), 1),
            _createElementVNode("button", {
              id: "estimated-button",
              onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.calculateEstimation && _ctx.calculateEstimation(...args))),
              class: "btn btn-sm btn-primary",
              disabled: _ctx.mindmapData.children.length == 0
            }, _toDisplayString(_ctx.$t("viewEstimatedCost")), 9, _hoisted_12)
          ])
        ]),
        _createElementVNode("div", _hoisted_13, [
          (_ctx.mindmapData)
            ? (_openBlock(), _createBlock(_component_Mindmap, {
                key: 0,
                ref: "test",
                root: _ctx.mindmapData,
                "onUpdate:root": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.mindmapData) = $event)),
                onEditNode: _ctx.handleEditNode,
                onDeleteNode: _ctx.handleDeleteNode,
                onAddNode: _ctx.handleAddNode,
                onAddModule: _ctx.addModule
              }, null, 8, ["root", "onEditNode", "onDeleteNode", "onAddNode", "onAddModule"]))
            : _createCommentVNode("", true)
        ])
      ]),
      _: 1
    })
  ], 64))
}